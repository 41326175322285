import React from 'react';


import ImageCycler from './Slideshow';
import caseImg from '../images/noBGImages/IMG_9350.png'

function ProductIntro() {
    return (
        <div className="intro">
            <div className='introText'>
                <p>Introducing</p><h1>EpiQCase</h1>
                <p className='slogan'>The product built to suit your lifestyle {"\n"} 
                and your allergies.</p>
            </div>
            {/*<ImageCycler/> */}
            {/*<img className="introPic" title="Product Image" src={introImage}></img>*/}

            <div className="product">
                {/* <h1 className='textAbove'>The Case</h1> */}
                <img className="frontpageimage" src={caseImg}></img>
                
            </div> 

        </div>
    );
}

export default ProductIntro;