import React from 'react';

function UsageInfo() {
    return (
        <div className="description">
            <p>Use EpiQCase to carry your AuviQ on the go at all times. Securely attach to your phone or to any flat surface. </p>
        </div>
    );
}

export default UsageInfo;